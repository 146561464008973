import React from 'react';

function Minecraft() {
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-1 gap-8 items-center">
          {/* Text content */}
          <div className="space-y-6">
            <h2 className="text-6xl font-extrabold leading-none tracking-tight">
              Minecraft<br/><span className="text-red-600">Server</span>
            </h2>
              <p className="text-xl">
                Willkommen auf den [Lastr~] Darkmoon Minecraft-Servern! 🌙<br/>
                Erlebe Minecraft wie nie zuvor auf unseren speziell angepassten Servern, die für jeden Spielertyp das perfekte Abenteuer bieten. Egal ob du klassische Vanilla-Action, kreative Modded-Server oder technische Herausforderungen suchst – [Lastr~] Darkmoon bietet dir die ultimative Minecraft-Erfahrung!<br/>
                <br/>
                Unsere Server im Überblick<br/>
                🌌 [Lastr~] Darkmoon (Forge)<br/>
                Tauche ein in eine Welt voller aufregender Mods und Features! Unser Forge-Server bietet dir epische Modpacks, mit denen du die Grenzen von Minecraft sprengen kannst. Entdecke neue Dimensionen, Maschinen, Magie und vieles mehr!<br/>
                <br/>
                🛠️ [Lastr~] Darkmoon (Fabric)<br/>
                Für Technik-Liebhaber und Modding-Enthusiasten! Unser Fabric-Server punktet mit schlanken, leistungsstarken Mods, die deinen Spielstil erweitern, ohne die Performance zu beeinträchtigen. Perfekt für effiziente und kreative Spieler.<br/>
                <br/>
                🌍 [Lastr~] Darkmoon (Vanilla)<br/>
                Erlebe Minecraft in seiner reinsten Form! Unser Vanilla-Server ist ideal für alle, die die klassische Minecraft-Erfahrung genießen wollen – ob alleine, mit Freunden oder im Team.<br/>
                <br/>
                Warum auf [Lastr~] Darkmoon spielen?<br/>
                ⭐ Gemeinschaft & Teamwork<br/>
                Tritt unserer wachsenden Community bei und erlebe den Spaß am gemeinsamen Bauen, Erkunden und Überleben.<br/>
                <br/>
                ⚙️ Optimierte Serverleistung<br/>
                Unsere Server sind sorgfältig optimiert, um dir ein flüssiges und lagfreies Spielerlebnis zu bieten – egal, für welchen Server du dich entscheidest.<br/>
                <br/>
                🎉 Regelmäßige Events<br/>
                Von spannenden Bauevents bis hin zu PvP-Turnieren – auf unseren Servern ist immer etwas los!<br/>
                <br/>
                🗺️ Einzigartige Welten<br/>
                Erkunde auf jedem Server individuell generierte Welten mit faszinierenden Biomen, Strukturen und Herausforderungen.<br/>
                <br/>
                🔒 Sicher & fair<br/>
                Dank aktiver Moderation und klarer Regeln garantieren wir dir eine sichere und faire Spielumgebung.<br/>
                <br/>
                Wie kannst du mitspielen?<br/>
                1️⃣ [Lastr~] Darkmoon (Forge)<br/>
                👉 Lade unser Modpack herunter und betrete die modifizierte Minecraft-Welt voller Abenteuer!<br/>
                <br/>
                2️⃣ [Lastr~] Darkmoon (Fabric)<br/>
                👉 Nutze die leistungsstarke Fabric-Plattform, um die besten Mods zu erleben.<br/>
                <br/>
                3️⃣ [Lastr~] Darkmoon (Vanilla)<br/>
                👉 Verbinde dich direkt mit unserer klassischen Minecraft-Welt – ohne Mods, aber mit endlosem Spaß!<br/>
                <br/>
                Schließe dich uns an!<br/>
                Egal ob Vanilla, Forge oder Fabric – [Lastr~] Darkmoon bietet dir alles, was Minecraft-Fans lieben. Erschaffe, erlebe und kämpfe gemeinsam mit einer starken Community auf unseren Servern.<br/>
                <br/>
                → Server beitreten und loslegen<br/>
                <br/>
                🌙 Wir freuen uns darauf, dich auf den [Lastr~] Darkmoon-Servern zu sehen! 🌙<br/>
              </p><br/><br />
            <a href="https://discord.gg/hWNTT4Tr" target="_blank" rel="noreferrer">
              <button className="bg-red-600 hover:bg-red-700 text-white font-bold py-3 px-6 text-lg uppercase tracking-wider transition-colors duration-300">
                Zum Discord
              </button>
            </a>
          </div>
        </div>
    </div>
  );
}

export default Minecraft;
