import React from 'react';

function Gta5TFC() {
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-1 gap-8 items-center">
          {/* Text content */}
          <div className="space-y-6">
            <h2 className="text-6xl font-extrabold leading-none tracking-tight">
                The Forgotten City <br/><span className="text-red-600">GTA5 DE RP Server</span>
            </h2>
              <p className="text-xl">
                  <img
                      src="/tfcpaper.png"
                      className="text-image-float"
                      alt="The Forgotten City Zeitung"
                      width="400"
                  />
                  Wir freuen uns, euch mitzuteilen, dass unser GTA RP Server am 02.02. offiziell seine Tore öffnet!<br/>
                  <br/>
                  🌍 Das Projekt spielt in einer postapokalyptischen Welt, gezeichnet von den Folgen eines verheerenden
                  Reaktorunfalls – seid bereit, in eine einzigartige und immersive Erfahrung einzutauchen.<br/>
                  <br/>
                  🔧 Was erwartet euch?<br/>
                  <br/>
                  Eine düstere, detaillierte Welt voller Herausforderungen und Geheimnisse.
                  Innovative Skripte und Mechaniken, die kontinuierlich verbessert werden.
                  Eine aktive Community und spannende RP-Möglichkeiten.<br/>
                  <br/>
                  🎯 Was passiert bis zum Release? Hinter den Kulissen arbeiten wir mit Hochdruck an der
                  Fertigstellung<br/>
                  <br/>
                  <b>Performance-Tuning:</b> Wir verbessern die Skripte, um ein reibungsloses Spielerlebnis zu
                  gewährleisten.<br/>
                  <br/>
                      <b>Website-Aufbau:</b> Bald erhaltet ihr Zugriff auf eine zentrale Plattform mit allen wichtigen
                  Informationen und Funktionen.<br/>
                  <br/>
                          <b>Belastungstests:</b> Wir prüfen die Serverstabilität, damit alles perfekt läuft.<br/>
                  <br/>
                  🚀 <b>Kontinuierliche Verbesserungen:</b><br/>
                  Auch nach dem Release werden wir euch regelmäßig mit Updates und
                  neuen Inhalten versorgen, um das Spielerlebnis noch besser zu machen.<br/>
              </p><br/><br />
            <a href="https://discord.gg/4bUsVjtn" target="_blank" rel="noreferrer">
              <button className="bg-red-600 hover:bg-red-700 text-white font-bold py-3 px-6 text-lg uppercase tracking-wider transition-colors duration-300">
                Zum GTA5 RP Discord
              </button>
            </a>
          </div>
        </div>
    </div>
  );
}

export default Gta5TFC;
