import React from 'react';

function COD4() {
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-1 gap-8 items-center">
          {/* Text content */}
          <div className="space-y-6">
            <h2 className="text-6xl font-extrabold leading-none tracking-tight">
              Call of Duty 4<br/><span className="text-red-600">Server</span>
            </h2>
              <p className="text-xl">
                Willkommen auf unserem Call of Duty 4: Modern Warfare-Server!<br/>
                <br/>
                Erlebe packende Schlachten, taktisches Gameplay und die unverwechselbare Atmosphäre eines Klassikers, der die Shooter-Welt revolutioniert hat. Unser Server bietet dir die ideale Mischung aus nostalgischem Gameplay und modernen Features, um das ultimative Spielerlebnis zu garantieren.<br/>
                <br/>
                Was dich erwartet:<br/>
                🎮 Klassische Action: Tauche ein in die ikonische Multiplayer-Erfahrung von CoD 4 mit perfekt abgestimmtem Gunplay und dynamischen Maps.<br/>
                🔧 Optimierte Performance: Unser Server läuft stabil und bietet eine reibungslose Spielerfahrung – für jede Situation bereit.<br/>
                🤝 Gemeinschaftsgefühl: Spiele mit einer aktiven Community, die sowohl neue als auch erfahrene Spieler willkommen heißt.<br/>
                🎯 Taktik & Teamplay: Schließe dich mit anderen Spielern zusammen, um die Schlachtfelder zu dominieren. Teamwork ist der Schlüssel zum Sieg!<br/>
                <br/>
                Server-Features:<br/>
                Klassische und Custom-Maps: Neben den beliebten Original-Karten bieten wir spannende neue Karten für mehr Abwechslung.<br/>
                Vielfältige Modi: Erlebe Klassiker wie Team Deathmatch, Domination oder Search & Destroy.<br/>
                Fair Play: Dank aktiver Moderation und Anti-Cheat-Systemen steht einem fairen Spielerlebnis nichts im Weg.<br/>
                Regelmäßige Events: Nimm an einzigartigen Events teil und sichere dir besondere Belohnungen.<br/>
                Mod-Unterstützung: Unser Server ist für beliebte Mods optimiert, um das Gameplay weiter zu individualisieren.<br/>
                <br/>
                Server-Informationen:<br/>
                ⚔️ Spielmodi: Team Deathmatch, Domination, Search & Destroy und mehr<br/>
                ⏰ Serverzeiten: 24/7 verfügbar<br/>
                💬 Community-Discord: Tausche dich mit anderen Spielern aus, erhalte Neuigkeiten und finde Mitspieler<br/>
                🎮 Server IP: s1.last-revenge.de<br/>
                <br/>
                Warum unser Server?<br/>
                Wir legen Wert auf Qualität, Fairness und Community. Egal, ob du ein Veteran bist, der sich an die glorreichen Tage von Modern Warfare erinnern möchte, oder ein Neuling, der die Wurzeln der modernen Shooter kennenlernen will – bei uns bist du richtig!<br/>
                <br/>
                Bereit für den Einsatz?<br/>
                Schließe dich uns an und werde Teil unserer Community. Der Kampf beginnt jetzt – wir sehen uns auf dem Schlachtfeld!<br/>
              </p><br/><br />
            <a href="https://discord.gg/hWNTT4Tr" target="_blank" rel="noreferrer">
              <button className="bg-red-600 hover:bg-red-700 text-white font-bold py-3 px-6 text-lg uppercase tracking-wider transition-colors duration-300">
                Zum Discord
              </button>
            </a>
          </div>
        </div>
    </div>
  );
}

export default COD4;
