import React from 'react';

function Scum() {
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-1 gap-8 items-center">
          {/* Text content */}
          <div className="space-y-6">
            <h2 className="text-6xl font-extrabold leading-none tracking-tight">
              Scrum - Black Bear<br/><span className="text-red-600">Server</span>
            </h2>
              <p className="text-xl">
                Black Bear Championship – Eine Herausforderung, die Legenden schafft!<br/>
                <br/>
                Das Black Bear Championship ist mehr als nur ein Turnier – es ist die ultimative Prüfung für Taktiker, Kämpfer und Teamplayer. In diesem einzigartigen Event treffen nur die Besten aufeinander, um ihre Fähigkeiten in spannenden und anspruchsvollen Wettkämpfen zu messen. Doch das Championship ist nicht nur ein Wettbewerb – es ist ein Erlebnis, das Spieler und Zuschauer gleichermaßen in seinen Bann zieht.<br/>
                <br/>
                🐾 Das Konzept des Black Bear Championship<br/>
                Das Black Bear Championship wurde entwickelt, um die Essenz von Stärke, Geschick und Gemeinschaft zu vereinen. Es richtet sich an Spieler und Teams, die bereit sind, über ihre Grenzen hinauszugehen, und setzt auf ein System, das Strategie und individuelle Fertigkeiten gleichermaßen fordert.<br/>
                <br/>
                Herausfordernde Formate<br/>
                Das Championship bietet verschiedene Wettkampfformen, darunter:<br/>
                <br/>
                Einzelkämpfe: Die perfekte Bühne, um individuelle Stärke zu demonstrieren.<br/>
                Teamwettbewerbe: Wo Zusammenarbeit und Strategie den Unterschied machen.<br/>
                Spezialaufgaben und Missionen: Die perfekte Kombination aus taktischer Tiefe und spielerischem Können.<br/>
                <br/>
                Dynamische Herausforderungen<br/>
                Kein Kampf gleicht dem anderen: Jedes Match bringt einzigartige Szenarien, angepasst an die Spielmechanik und die Stärke der Teilnehmer. Dies garantiert Spannung und fordert die Teilnehmer heraus, kreativ zu sein und sich schnell an neue Situationen anzupassen.<br/>
                <br/>
                🔥 Was macht das Black Bear Championship einzigartig?<br/>
                Individuelle Anpassung: Jedes Event wird speziell auf die Teilnehmer zugeschnitten, wodurch der Wettbewerb immer fair und gleichzeitig anspruchsvoll bleibt.<br/>
                <br/>
                Exklusive Inhalte: Die Sieger können nicht nur den Titel des Black Bear Champions tragen, sondern erhalten auch einzigartige Belohnungen, die nur innerhalb dieses Championships verfügbar sind.<br/>
                <br/>
                Gemeinschaft im Fokus: Das Event stärkt die Bindung zwischen Spielern und ihren Teams. Es geht nicht nur ums Gewinnen, sondern auch darum, eine Geschichte zu schreiben und Teil einer Legende zu werden.<br/>
                <br/>
                Immersives Setting: Die Kämpfe finden in thematisch gestalteten Arenen und Gebieten statt, die eine außergewöhnliche Atmosphäre schaffen und die Spieler mitten ins Geschehen ziehen.<br/>
                <br/>
                🏅 Vision und Ziele<br/>
                Das Black Bear Championship soll als Aushängeschild für strategisches Können und sportlichen Ehrgeiz dienen. Es ist eine Plattform, die:<br/>
                <br/>
                Fairness und Herausforderung vereint, die besten Spieler und Teams zusammenbringt und unvergessliche Momente für alle Beteiligten schafft.<br/>
                <br/>
                Unsere Vision ist es, ein Event zu kreieren, das sich von der Masse abhebt und sowohl die Fähigkeiten der Teilnehmer als auch die Begeisterung der Zuschauer auf ein neues Level hebt.<br/>
                <br/>
                Bist du bereit, dich der Herausforderung zu stellen und deinen Namen in die Geschichte des Black Bear Championship einzutragen?<br/>
                <br/>
                Die Bühne gehört dir – zeig, was du drauf hast!<br/>
              </p><br/><br />
            <a href="https://discord.gg/hWNTT4Tr" target="_blank" rel="noreferrer">
              <button className="bg-red-600 hover:bg-red-700 text-white font-bold py-3 px-6 text-lg uppercase tracking-wider transition-colors duration-300">
                Zum Discord
              </button>
            </a>
          </div>
        </div>
    </div>
  );
}

export default Scum;
