import React from 'react';
import DataTable from '../components/DataTable';

const News = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Datum',
        accessor: 'date', // Schlüssel für Daten
      },
      {
        Header: 'Änderungsbeschreibung',
        accessor: 'description',
      },
    ],
    []
  );

  const data = React.useMemo(
    () => [
        {
            date: '22.01.2025',
            description: 'Unsere Projekte: Texte erweitert und fertiggestellt',
        },
        {
            date: '13.01.2025',
            description: 'Teamseite angepasst.',
        },
        {
            date: '12.01.2025',
            description: 'Infoseite GTA5 The Forgotten City hinzugefügt.',
        },
        {
            date: '12.01.2025',
            description: 'Kleine Änderungen am Design.',
        },
        {
            date: '12.01.2025',
            description: 'Unsere Projekte hinzugefügt.',
        },
        {
            date: '12.01.2025',
            description: '404-Seite hinzugefügt.',
        },
        {
            date: '12.01.2025',
            description: 'Navbar verbessert',
        },
        {
            date: '12.01.2025',
            description: 'Grundstruktur der Navigation erstellt und erste Seiten (Home, News, Team) verlinkt.',
        },
    ],
    []
  );

  return (
    <div className="container my-5">
      <h1 className="text-center mb-4">News & Updates</h1>
      <DataTable columns={columns} data={data} />
    </div>
  );
};

export default News;
