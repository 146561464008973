import React from 'react';

function Rust() {
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-1 gap-8 items-center">
          {/* Text content */}
          <div className="space-y-6">
            <h2 className="text-6xl font-extrabold leading-none tracking-tight">
              Rust<br/><span className="text-red-600">Server</span>
            </h2>
              <p className="text-xl">
                Willkommen auf unserem Rust PvP-Server! 🛡️<br/>
                Bist du bereit für die ultimative Herausforderung in der Welt von Rust? Unser Server bietet dir ein intensives PvP-Erlebnis, bei dem Überleben, Strategie und Kampfgeist gefragt sind. Hier geht es nicht nur ums Bauen und Erkunden – hier zählt, wer die Nerven behält und den Sieg erringt!<br/>
                <br/>
                Was erwartet dich auf unserem Server?<br/>
                🔥 Pure PvP-Action<br/>
                Auf unserem Server steht der Wettbewerb im Mittelpunkt. Kämpfe um Ressourcen, verteidige deine Basis und zeige, dass du der stärkste Spieler bist!<br/>
                <br/>
                🏗️ Schnelles Base-Building<br/>
                Dank erhöhter Ressourcen- und Sammelraten kannst du schnell eine sichere Basis errichten und dich auf die actionreichen Kämpfe konzentrieren.<br/>
                <br/>
                ⚔️ Clans & Allianzen<br/>
                Schließe dich mit anderen Spielern zusammen, bilde mächtige Clans und dominiere die Karte gemeinsam – oder geh deinen eigenen Weg als einsamer Wolf.<br/>
                <br/>
                🌐 Aktive Karte & spannende Gebiete<br/>
                Erkunde eine dynamische Karte mit interessanten Orten, strategischen Ressourcenpunkten und gefährlichen Monstern.<br/>
                <br/>
                🚁 Events & Airdrops<br/>
                Regelmäßige Airdrops und spezielle PvP-Events sorgen dafür, dass keine Langeweile aufkommt und immer etwas zu kämpfen gibt.<br/>
                <br/>
                Server-Features im Überblick<br/>
                Erhöhte Ressourcen-Raten: Schneller Einstieg ins Spiel.<br/>
                Sichere Offline-Mechaniken: Schutz, auch wenn du mal offline bist.<br/>
                Regelmäßige Wipes: Frische Herausforderungen und ein fairer Start für alle Spieler.<br/>
                Aktive Moderation: Unsere Admins sorgen für ein faires und ausgeglichenes Spielerlebnis.<br/>
                Anti-Cheat-System: Ein sicheres Umfeld, damit Skill zählt und nicht unfaire Mittel.<br/>
                <br/>
                Warum auf unserem Server spielen?<br/>
                ✅ Spannung und Adrenalin: Kämpfe gegen andere Spieler und beweise dein Können.<br/>
                ✅ Teamplay oder Solo: Egal ob du mit Freunden spielst oder alleine – hier bist du willkommen.<br/>
                ✅ Regelmäßige Updates: Immer neue Inhalte und Verbesserungen für dein Spielerlebnis.<br/>
                ✅ Freundliche Community: Trotz PvP-Action fördern wir ein respektvolles Miteinander.<br/>
                <br/>
                Schließe dich uns an!<br/>
                Komm auf unseren Server und erlebe Rust PvP, wie es sein sollte: spannend, herausfordernd und unvergesslich. Zeig, dass du die wahre Nummer eins bist – wir freuen uns auf dich!<br/>
                <br/>
                → Server beitreten und loslegen<br/>
                <br/>
                🛡️ Der Kampf um das Überleben beginnt hier – bist du bereit? 🛡️<br/>
              </p><br/><br />
            <a href="https://discord.gg/hWNTT4Tr" target="_blank" rel="noreferrer">
              <button className="bg-red-600 hover:bg-red-700 text-white font-bold py-3 px-6 text-lg uppercase tracking-wider transition-colors duration-300">
                Zum Discord
              </button>
            </a>
          </div>
        </div>
    </div>
  );
}

export default Rust;
