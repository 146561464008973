import React from 'react';

function CounterStrike2() {
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-1 gap-8 items-center">
          {/* Text content */}
          <div className="space-y-6">
            <h2 className="text-6xl font-extrabold leading-none tracking-tight">
              Counter Strike 2<br/><span className="text-red-600">Server</span>
            </h2>
              <p className="text-xl">
                Willkommen auf unserem Counter-Strike 2 Server! 🎯<br/>
                Tauche ein in die Welt des taktischen Shooters und erlebe Counter-Strike 2 auf einem Server, der speziell für spannende Matches und eine großartige Community ausgelegt ist. Egal, ob du ein Veteran der Serie bist oder neu einsteigst – unser Server bietet das perfekte Umfeld, um deine Skills zu zeigen und dich weiterzuentwickeln.<br/>
                <br/>
                Warum auf unserem Server spielen?<br/>
                🎮 Perfekte Spielumgebung<br/>
                Unsere Server sind für maximale Performance und Stabilität optimiert. Genieße ein flüssiges Spielerlebnis mit minimalem Ping und ohne störende Unterbrechungen.<br/>
                <br/>
                ⚙️ Vielfalt an Spielmodi<br/>
                Von klassischen Bombenentschärfungs-Matches bis hin zu innovativen Fun-Modi – unser Server bietet eine breite Auswahl, die für Abwechslung sorgt.<br/>
                <br/>
                🤝 Teamplay & Community<br/>
                Auf unserem Server steht das Teamplay im Vordergrund. Verbünde dich mit anderen Spielern, kommuniziere taktisch und arbeite gemeinsam auf den Sieg hin.<br/>
                <br/>
                🛡️ Anti-Cheat-System<br/>
                Dank eines zuverlässigen Anti-Cheat-Systems sorgen wir für eine faire und sichere Spielumgebung, in der Skill und Strategie zählen.<br/>
                <br/>
                🏆 Turniere & Events<br/>
                Regelmäßige Turniere und besondere Community-Events sorgen dafür, dass dir nie langweilig wird und du die Chance auf exklusive Belohnungen hast.<br/>
                <br/>
                Server-Features im Überblick<br/>
                24/7 Verfügbarkeit: Unser Server ist rund um die Uhr online, damit du jederzeit spielen kannst.<br/>
                Einstiegsfreundlich: Ob Anfänger oder Profi – jeder ist willkommen!<br/>
                Regelmäßige Updates: Wir halten den Server auf dem neuesten Stand, um dir immer das beste Spielerlebnis zu bieten.<br/>
                Freundliche Moderation: Unser engagiertes Team sorgt für ein angenehmes und faires Miteinander.<br/>
                Map-Vielfalt: Wähle aus einer Vielzahl beliebter Karten oder entdecke neue, spannende Kreationen.<br/>
                <br/>
                Wie kannst du mitspielen?<br/>
                1️⃣ Starte Counter-Strike 2 auf deinem PC.<br/>
                2️⃣ Verbinde dich über unsere Server-IP (wird auf der Website bereitgestellt).<br/>
                3️⃣ Wähle deinen Lieblingsmodus und starte dein Match.<br/>
                <br/>
                Sei Teil unserer Community!<br/>
                Auf unserem Counter-Strike 2 Server erwarten dich packende Matches, taktische Herausforderungen und eine aktive Community, die dich mit offenen Armen empfängt. Zeig dein Können, dominiere die Matches und werde Teil unserer Erfolgsgeschichte!<br/>
                <br/>
                → Server beitreten und loslegen<br/>
                <br/>
                🎯 Das Match beginnt – bist du bereit? 🎯<br/>
              </p><br/><br />
            <a href="https://discord.gg/hWNTT4Tr" target="_blank" rel="noreferrer">
              <button className="bg-red-600 hover:bg-red-700 text-white font-bold py-3 px-6 text-lg uppercase tracking-wider transition-colors duration-300">
                Zum Discord
              </button>
            </a>
          </div>
        </div>
    </div>
  );
}

export default CounterStrike2;
