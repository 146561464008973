import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center vh-70 bg-dark text-white border-radius-15">

        <img
            src="/lr-logo.png"
            alt="Last Revenge Logo"
            width="50%"
            className="text-center animate-pulse relative z-10 transform rotate-3 transition-transform hover:rotate-0 duration-300"
        />
        
        <h1 className="display-1 fw-bold text-danger">404</h1>
        <p className="fs-3">Oops! Seite nicht gefunden</p>
        <p className="text-muted">
            Die Seite, die Sie suchen, scheint nicht zu existieren.
        </p>
        <Link to="/" className="btn btn-outline-light mt-4 px-4 py-2">
        Zurück zur Startseite
        </Link>
    </div>
  );
};

export default NotFound;
