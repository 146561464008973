import React from 'react';

function VRising() {
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-1 gap-8 items-center">
          {/* Text content */}
          <div className="space-y-6">
            <h2 className="text-6xl font-extrabold leading-none tracking-tight">
              VRising<br/><span className="text-red-600">Server</span>
            </h2>
              <p className="text-xl">
                Willkommen auf unserem V Rising PvE-Server!<br/>
                <br/>
                Tauche ein in eine düstere und faszinierende Welt voller Geheimnisse, Herausforderungen und unbegrenzter Möglichkeiten. Unser PvE-Server bietet dir die perfekte Umgebung, um als Vampir-Lord dein Imperium aufzubauen, neue Allianzen zu schmieden und die Tiefen der Spielwelt zu erkunden – ganz ohne PvP-Stress.<br/>
                <br/>
                Was dich erwartet:<br/>
                🦇 Einsteigerfreundlich: Unser Server ist perfekt für Anfänger und erfahrene Spieler gleichermaßen. Wir bieten eine entspannte Atmosphäre und hilfreiche Community-Mitglieder.<br/>
                🏰 Baue dein Imperium: Erschaffe deine eigene Festung, erweitere sie mit mächtigen Upgrades und verteidige sie gegen die Herausforderungen der dunklen Welt.<br/>
                ⚔️ Herausfordernde Bosskämpfe: Stelle dich mächtigen Feinden, sammle wertvolle Ressourcen und entdecke mächtige Fähigkeiten, um deinen Vampir zu perfektionieren.<br/>
                🌍 Eine lebendige Welt: Entdecke eine detailreiche Spielwelt voller dynamischer Events, versteckter Geheimnisse und unendlicher Möglichkeiten zur Entfaltung.<br/>
                🛠️ Quality-of-Life-Anpassungen: Wir haben den Server mit hilfreichen Modifikationen und optimierten Einstellungen ausgestattet, um dir ein unvergleichliches Spielerlebnis zu bieten.<br/>
                🤝 Kooperatives Gameplay: Spiele allein oder schließe dich mit anderen Vampir-Lords zusammen, um die Welt zu erobern.<br/>
                <br/>
                Server-Features:<br/>
                PvE-Fokus: Keine PvP-Kämpfe, nur gemeinsame Abenteuer und Kooperation.<br/>
                Regelmäßige Events: Nimm an spannenden Events teil, die dir die Möglichkeit bieten, einzigartige Belohnungen zu ergattern.<br/>
                Aktive Administration: Unser Team steht dir bei Fragen und Problemen jederzeit zur Seite.<br/>
                Community-Discord: Vernetze dich mit anderen Spielern, teile deine Fortschritte und erhalte die neuesten Infos.<br/>
                <br/>
                Werde Teil unserer Vampir-Community!<br/>
                Mach mit und erlebe V Rising auf eine völlig neue Art. Wir freuen uns, dich bald auf unserem Server begrüßen zu dürfen. Lass uns gemeinsam in die Dunkelheit eintauchen und dein Vampir-Abenteuer beginnen!<br/>
                <br/>
                📅 Onlinezeit: 24/7 verfügbar<br/>
                <br/>
                Bereit, dein Vampir-Imperium aufzubauen? Schließe dich uns an!<br/>
              </p><br/><br />
            <a href="https://discord.gg/hWNTT4Tr" target="_blank" rel="noreferrer">
              <button className="bg-red-600 hover:bg-red-700 text-white font-bold py-3 px-6 text-lg uppercase tracking-wider transition-colors duration-300">
                Zum Discord
              </button>
            </a>
          </div>
        </div>
    </div>
  );
}

export default VRising;
