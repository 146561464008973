import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container">
        {/* Navigation Items */}
        <div id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className="btn btn-link nav-link" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="btn btn-link nav-link" to="/news">
                News
              </Link>
            </li>
            <li className="nav-item">
              <Link className="btn btn-link nav-link" to="/team">
                Team
              </Link>
            </li>
            <li className="nav-item dropdown">
              <a className="btn btn-link dropdown-toggle" role="button" href="/#" data-bs-toggle="dropdown" aria-expanded="false">
                Unsere Projekte
              </a>

              <ul className="dropdown-menu">
                <li><Link to="/gta5tfc" className="dropdown-item">GTA5 - The Forgotten City RP</Link></li>
                <li><Link to="/palworld" className="dropdown-item">Palworld – Freundschaftsinsel</Link></li>
                <li><Link to="/minecraft" className="dropdown-item">Minecraft</Link></li>
                <li><Link to="/rust" className="dropdown-item">Rust – Bloody Wasteland</Link></li>
                <li><Link to="/counterstrike2" className="dropdown-item">Counter Strike 2 – Tactical Front</Link></li>
                <li><Link to="/scum" className="dropdown-item">Scum – Black Bear</Link></li>
                <li><Link to="/vrising" className="dropdown-item">Vrising - Dark Forest Legends </Link></li>
                <li><Link to="/arma3" className="dropdown-item">Arma 3 – Alpha Battalion (MilsimRP)</Link></li>
                <li><Link to="/cod4" className="dropdown-item">Call of Duty MW – Shadow Wolf</Link></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
