import React from 'react';

function Home() {
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          {/* Text content */}
          <div className="space-y-6">
            <h2 className="text-6xl font-extrabold leading-none tracking-tight">
              JOIN YOUR <br /><span className="text-red-600">LAST REVENGE</span>
            </h2>
            <p className="text-xl">
              Betrete eine Welt voller brutaler Herausforderungen und dunkler Abenteuer.<br />
              Bist Du bereit, dich deinen Ängsten zu stellen?
            </p><br /><br />
            <a href="https://discord.gg/hWNTT4Tr" target="_blank" rel="noreferrer">
              <button className="bg-red-600 hover:bg-red-700 text-white font-bold py-3 px-6 text-lg uppercase tracking-wider transition-colors duration-300">
                Tritt unserem Discord bei!
              </button>
            </a>
          </div>
          
          {/* Image container */}
          <div className="relative">
            {/* Character image */}
            <img
              src="/lr-logo.png"
              alt="Last Revenge Logo"
              className="animate-pulse relative z-10 transform rotate-3 transition-transform hover:rotate-0 duration-300"
            />
          </div>
        </div>
    </div>
  );
}

export default Home;
