import React from 'react';

function Arma3() {
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-1 gap-8 items-center">
          {/* Text content */}
          <div className="space-y-6">
            <h2 className="text-6xl font-extrabold leading-none tracking-tight">
              Arma 3<br/><span className="text-red-600">Server</span>
            </h2>
              <p className="text-xl">
                Alfa Milsim Roleplay – Realismus trifft auf fesselndes Rollenspiel<br/>
                <br/>
                Tauche ein in die immersive Welt von Alfa Milsim Roleplay, einer einzigartigen Kombination aus militärischer Simulation und tiefgründigem Rollenspiel in Arma 3. Bei uns erlebst du authentische Einsätze, strategische Herausforderungen und eine lebendige Community, die Wert auf Realismus und gemeinsame Geschichten legt.<br/>
                <br/>
                🌍 Unsere Mission<br/>
                Bei Alfa Milsim Roleplay geht es um mehr als nur Kämpfe. Du bist Teil einer organisierten militärischen Einheit, die in einer realistisch gestalteten Welt operiert. Ob Aufklärungsmissionen, Rettungseinsätze oder großangelegte Schlachten – jede Operation wird sorgfältig geplant und mit taktischer Präzision ausgeführt.<br/>
                <br/>
                🎯 Das macht uns aus<br/>
                Realismus im Fokus<br/>
                Wir setzen auf authentische Ausrüstung, Taktiken und Einsatzstrukturen, um dir das Gefühl zu geben, Teil einer echten Militäreinheit zu sein.<br/>
                <br/>
                Rollenspiel auf höchstem Niveau<br/>
                Deine Entscheidungen beeinflussen nicht nur deinen Charakter, sondern auch die Welt um dich herum. Vom Alltag in der Basis bis hin zu Missionen – dein Handeln hat Konsequenzen.<br/>
                <br/>
                Dynamische Kampagnen<br/>
                Unsere Missionen sind lebendig und beeinflusst von den Aktionen der Spieler. Kein Einsatz ist wie der andere, und jede Entscheidung zählt.<br/>
                <br/>
                Gemeinschaft und Teamarbeit<br/>
                In unserer Community steht der Teamgeist im Vordergrund. Zusammenarbeit, Kommunikation und Vertrauen sind der Schlüssel zum Erfolg.<br/>
                <br/>
                🛡 Für wen ist Alfa Milsim Roleplay geeignet?<br/>
                MilSim-Enthusiasten: Du liebst realistische Szenarien und eine strukturierte Spielerfahrung.<br/>
                Rollenspieler: Du möchtest deinen Charakter entwickeln und in eine immersive Welt eintauchen.<br/>
                Teamplayer: Du arbeitest gerne mit anderen zusammen, um komplexe Herausforderungen zu meistern.<br/>
                <br/>
                🚀 Deine Reise beginnt hier<br/>
                Werde Teil von Alfa Milsim Roleplay und erlebe ein unvergleichliches Spielerlebnis in Arma 3. Schließe dich einer engagierten Community an, stelle dich taktischen Herausforderungen und gestalte deine eigene Geschichte in einer Welt, die von Realismus und Rollenspiel lebt.<br/>
                <br/>
                "Willkommen bei Alfa – wo jede Mission zählt und jeder Spieler Teil einer großen Geschichte wird."<br/>
              </p><br/><br />
            <a href="https://discord.gg/hWNTT4Tr" target="_blank" rel="noreferrer">
              <button className="bg-red-600 hover:bg-red-700 text-white font-bold py-3 px-6 text-lg uppercase tracking-wider transition-colors duration-300">
                Zum Discord
              </button>
            </a>
          </div>
        </div>
    </div>
  );
}

export default Arma3;
