import React from 'react';
import {Routes, Route, Link} from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import News from './pages/News';
import Team from './pages/Team';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Gta5TFC from "./pages/Gta5TFC";
import Palworld from "./pages/Palworld";
import Minecraft from "./pages/Minecraft";
import Rust from "./pages/Rust";
import CounterStrike2 from "./pages/CounterStrike2";
import VRising from "./pages/VRising";
import Scum from './pages/Scrum';
import COD4 from './pages/COD4';
import Arma3 from './pages/Arma3';

function App() {
  return (
    <div className="min-h-screen bg-black text-white font-sans">
      {/* Header */}
        <header className="border-b-4 border-red-600 p-4">
            <Link to="/">
                <img
                    src="/lr-logo.png"
                    alt="Last Revenge Logo"
                    width="300"
                    className="relative z-10"
                />
            </Link>
        </header>

        <Navbar/>

        {/* Main content */}
        <main className="container mx-auto px-4 py-8">
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/news" element={<News/>}/>
                <Route path="/team" element={<Team />} />
                <Route path="/login" element={<Login />} />
                {/* Projekt Routen */}
                <Route path="/gta5tfc" element={<Gta5TFC />} />
                <Route path="/palworld" element={<Palworld />} />
                <Route path="/minecraft" element={<Minecraft />} />
                <Route path="/rust" element={<Rust />} />
                <Route path="/counterstrike2" element={<CounterStrike2 />} />
                <Route path="/scum" element={<Scum />} />
                <Route path="/vrising" element={<VRising />} />
                <Route path="/arma3" element={<Arma3 />} />
                <Route path="/cod4" element={<COD4 />} />

                {/* 404 Route */}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </main>
      
      {/* Footer */}
      <footer className="mt-16 border-t-4 border-red-600 p-4 text-center">
        <p className="uppercase tracking-widest">Last-Revenge &copy; 2025</p>
      </footer>
    </div>
  );
}

export default App;
