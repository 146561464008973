import React from 'react';
import teamData from '../data/teamData';

const Team = () => {
  return (
      <div className="container my-5">
        <h1 className="text-center mb-4">Unser Team</h1>
        {teamData.map((category, index) => (
            <div key={index} className="mb-5">
              <h2 className="mb-3  p-1 text-bg-danger">{category.category}</h2>
              {category.projects.map((project, idx) => (
                  <div key={idx} className="mb-4">
                    <h3>{project.name}</h3>
                    {project.status && <p><strong>Status:</strong> {project.status}</p>}
                    <div className="row">
                      {project.members.length > 0 ? (
                          project.members.map((member, id) => (
                              <div key={id} className="col-md-4 mb-3">
                                <div className="card bg-dark text-white">
                                  <img
                                      src={member.image || "/images/placeholder.jpg"}
                                      className="card-img-top"
                                      alt={member.name}
                                      style={{ height: '200px', objectFit: 'contain', marginTop: '10px' }}
                                  />
                                  <div className="card-body">
                                    <h5 className="card-title">{member.name}</h5>
                                    <p className="card-text"><strong>{member.role}</strong></p>
                                    <p className="card-text">{member.description}</p>
                                  </div>
                                </div>
                              </div>
                          ))
                      ) : (
                          <p>Keine Teammitglieder zugeordnet.</p>
                      )}
                    </div>
                  </div>
              ))}
            </div>
        ))}
      </div>
  );
};

export default Team;
