import React from 'react';

function Palworld() {
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-1 gap-8 items-center">
          {/* Text content */}
          <div className="space-y-6">
            <h2 className="text-6xl font-extrabold leading-none tracking-tight">
              Palworld Freundschaftsinsel<br/><span className="text-red-600">Server</span>
            </h2>
              <p className="text-xl">
                Willkommen auf unserem Palworld-Server! 🌟<br />
                Tauche ein in die fantastische Welt von Palworld, einem einzigartigen Spiel, das Abenteuer, Überleben und Kreativität miteinander verbindet! Auf unserem Server erwarten dich spannende Herausforderungen, gemeinschaftliche Projekte und eine lebendige Community, die das Spielerlebnis auf ein neues Level hebt.<br />
                <br />
                Was ist Palworld?<br />
                Palworld ist ein Open-World-Abenteuer-Spiel, in dem du fantastische Kreaturen, die sogenannten "Pals", fangen, zähmen und trainieren kannst. Nutze ihre Fähigkeiten, um in einer gefährlichen Welt zu überleben, Ressourcen zu sammeln oder epische Schlachten zu schlagen. Ob du eine mächtige Fabrik aufbaust, deine Pals zur Arbeit schickst oder dich den Gefahren der Natur stellst – in Palworld sind die Möglichkeiten endlos!<br />
                <br />
                Was macht unseren Server besonders?<br />
                🌍 Gemeinsames Abenteuer<br />
                Erkunde die weite Welt von Palworld zusammen mit anderen Spielern. Ob im Koop-Modus oder in epischen PvP-Schlachten – Teamwork steht bei uns im Vordergrund!<br />
                <br />
                🏗️ Community-Projekte<br />
                Schließe dich mit anderen Spielern zusammen, um gigantische Bauwerke, Dörfer oder Basen zu errichten. Eurer Kreativität sind keine Grenzen gesetzt!<br />
                <br />
                🏆 Events & Herausforderungen<br />
                Nimm an spannenden Events teil, meistere einzigartige Herausforderungen und sichere dir exklusive Belohnungen.<br />
                <br />
                ⚙️ Optimierter Server<br />
                Unser Server bietet dir ein stabiles und lagfreies Spielerlebnis, damit du dich voll und ganz auf dein Abenteuer konzentrieren kannst.<br />
                <br />
                Features unseres Servers<br />
                🐾 Pals tauschen und handeln: Baue dein perfektes Team auf und tausche Kreaturen mit anderen Spielern.<br />
                🔨 Individuelle Bauprojekte: Nutze die Sandbox-Elemente von Palworld, um deine Traumwelt zu erschaffen.<br />
                ⚔️ Kämpfe und Arenen: Miss dich mit anderen Spielern in actionreichen Kämpfen oder kooperiere bei gefährlichen Missionen.<br />
                📜 Regelmäßige Updates: Unsere Server-Administratoren sorgen für neue Inhalte und spannende Erweiterungen.<br />
                <br />
                Schließe dich uns an!<br />
                Unsere Community freut sich auf dich! Werde Teil unseres Palworld-Servers und erlebe gemeinsam mit uns eine unvergessliche Reise in einer Welt voller Abenteuer.<br />
                <br />
                → Server beitreten und loslegen<br />
                <br />
                🌟 Dein nächstes Abenteuer beginnt hier – auf unserem Palworld-Server! 🌟
              </p><br/><br />
            <a href="https://discord.gg/hWNTT4Tr" target="_blank" rel="noreferrer">
              <button className="bg-red-600 hover:bg-red-700 text-white font-bold py-3 px-6 text-lg uppercase tracking-wider transition-colors duration-300">
                Zum Discord
              </button>
            </a>
          </div>
        </div>
    </div>
  );
}

export default Palworld;
