const teamData = [
    {
        category: "Hauptprojekte",
        projects: [
            {
                name: "Forgotten City",
                status: "Aktiv",
                members: [
                    {
                        name: "Piotr Dribbla",
                        role: "Projektleiter",
                        image: "/images/piotr.png",
                        description: "Piotr kümmert sich um die Leitung und Planung des Projekts."
                    },
                    {
                        name: "James Silver",
                        role: "Entwickler & Projektleiter",
                        image: "/images/james_animation.gif",
                        description: "James ist für die technische Umsetzung des Servers und der Webauftritte verantwortlich."
                    },
                    {
                        name: "Alejandro Mendez",
                        role: "Entwickler & Projektleiter",
                        image: "/images/alejandro.png",
                        description: "Alejandro ist für die Entwicklung und Wartung userer Individuellen Lösungen verantwortlich."
                    }
                ]
            }
        ]
    },
    {
        category: "Nebenprojekte",
        projects: [
            {
                name: "Arma 3 - Alpha Battalion (MilsimRP)",
                status: "",
                members: [
                    {
                        name: "Piotr Dribbla",
                        role: "Projektleiter",
                        image: "/images/piotr.png",
                        description: "Piotr kümmert sich um die Leitung und Planung des Projekts."
                    },
                    {
                        name: "Marcus Burnett",
                        role: "Stellv. Projektleiter",
                        image: "/images/burnett.png",
                        description: "Marcus kümmert sich ebenfalls um die Leitung und Planung des Projekts."
                    },
                ]
            }
        ]
    }
];

export default teamData;
